<template>
  <div class="product-item-box">
    <ul v-if="productList&&productList.length>0">
        <li v-for="(item,index) in productList" :key="index" @click="clickOpenProductDetailsHandler(item)">
            <div class="product-item-main-pic-box">
                <img class="product-item-main-pic" :src="item.productCoverUrl" alt="商品主图" >
            </div>
            <div class="product-item-main-info-box">
                <p class="product-item-main-info-title">{{ item.productIntroduction }}</p>
                <p class="product-item-main-info-game-zone">游戏区  |  {{ item.gameZone }}</p>
                <div class="product-item-main-info-price-box">
                    <p class="product-item-main-info-price-symbol">¥</p>
                    <p class="product-item-main-info-price-text">{{ item.productPrice }}</p>
                </div>
            </div>
        </li>
    </ul>
    <no-product-page v-else :boxWidth="1200" :boxHeight="624"></no-product-page>
  </div>
</template>

<script>
import NoProductPage from '@/views/components/no-product-page.vue';
import {getWebProductListByUserId} from '@/services/myCenter';
export default {
    name:"ProdectItem",
    components:{
        NoProductPage
    },
    data(){
        return{
            productList:[],
            requestParam:{
                pageNumber:1,
                pageSize:20
            },
            totalCount:0,
            isLastPage:false
        }
    },
    methods:{
        //加载商品列表
        async loadWebProductListByUserId(){
            try{
                const {data} = await getWebProductListByUserId(this.requestParam);
                if(data.code===200){
                    this.productList = data.data.data;
                    this.totalCount = data.data.totalCount;
                    this.isLastPage = data.data.isLastPage;
                }else{}
            }catch(err){}
        },
        //打开商品详情操作
        clickOpenProductDetailsHandler(item){
            this.$router.push({name:"accountProductDetails",params:{currentProduct:item}})
        }
    },
    created(){
        this.loadWebProductListByUserId();
    }
}
</script>

<style lang="less" scoped>
.product-item-box{
    width: 1200px;
    ul{
        width: 1200px;
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        li{
            margin-top: 30px;
            margin-left: 20px;
            background: #F8F9FA;
            border-radius: 4px;
            list-style: none;
            width: 275px;
            height: 282px;
            display: flex;
            flex-direction: column;
            .product-item-main-pic-box{
                width: 275px;
                height: 155px;
                .product-item-main-pic{
                    width: 275px;
                    height: 155px;
                }
            }
            .product-item-main-info-box{
                width: 275px;
                padding: 0 14px;
                .product-item-main-info-title{
                    margin-top: 9px;
                    width: 247px;
                    height: 42px;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #131415;
                    line-height: 21px;
                    text-align: left;
                    font-style: normal;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    word-break: break-all;
                }
                .product-item-main-info-game-zone{
                    margin-top: 14px;
                    height: 12px;
                    font-family: MicrosoftYaHei;
                    font-size: 12px;
                    color: #888888;
                    line-height: 12px;
                    text-align: left;
                    font-style: normal;
                }
                .product-item-main-info-price-box{
                    margin-top: 20px;
                    height: 16px;
                    display: flex;
                    align-items: flex-end;
                    .product-item-main-info-price-symbol{
                        width: 8px;
                        height: 12px;
                        font-family: DingTalk, DingTalk;
                        font-weight: normal;
                        font-size: 12px;
                        color: #F02233;
                        line-height: 12px;
                        text-align: left;
                        font-style: normal;
                    }
                    .product-item-main-info-price-text{
                        margin-left: 2px;
                        height: 16px;
                        font-family: DingTalk, DingTalk;
                        font-weight: normal;
                        font-size: 16px;
                        color: #F02233;
                        line-height: 16px;
                        text-align: left;
                        font-style: normal;
                    }
                }
            }
        }
    }
}
</style>