import request from "../../utils/request";

//获取我的订单
export const getWebOrderInfoList = (data)=>{
    return  request({
        method:'POST',
        url:'/order/getWebOrderInfoList',
        data
    })
}

//获取我的商品
export const getWebProductListByUserId = (data)=>{
    return  request({
        method:'POST',
        url:'/game/getWebProductListByUserId',
        data
    })
}

//获取我的收藏商品
export const getWebMyCollectProductListByUserId = (data)=>{
    return  request({
        method:'POST', 
        url:'/game/getWebMyCollectProductListByUserId',
        data
    })
}